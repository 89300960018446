





















import { Component, Vue } from 'vue-property-decorator'
import { GameModule, UserModule } from '@/store/modules'
import { Logs } from '@/api'

@Component
export default class CharacterPage extends Vue {
  @GameModule.State characters
  @GameModule.State userCharacters
  @GameModule.State viewCharacter
  @UserModule.State currentUser

  currentCharacter: Nullable<Character> = null
  currentCharacterBazaar = null
  nicknameChanges = [] as any[]

  get characterTabs() {
    return [
      {
        name: 'General',
        component: () => import('@/components/Character/CharacterProfile.vue'),
        data: this.currentCharacter,
        icon: 'UserIcon'
      },
      {
        name: 'Inventory',
        component: () => import('@/components/Character/CharacterInventory.vue'),
        data: this.currentCharacter?.inventory,
        icon: 'BriefcaseIcon'
      },
      {
        name: 'Pets & Partners',
        component: () => import('@/components/Character/CharacterMates.vue'),
        icon: 'GithubIcon',
        data: this.currentCharacter?.mates,
        disabled: true
      },
      {
        name: 'NosBazar',
        component: () => import('@/components/Character/CharacterBazaar.vue'),
        icon: 'LayoutIcon'
      },
      {
        name: 'Statistics',
        component: () => import('@/components/Character/CharacterStats.vue'),
        data: this.currentCharacter?.stats,
        icon: 'BarChartIcon'
      },
      {
        name: 'Nickname Changes',
        component: () => import('@/views/GM/NicknameChanges.vue'),
        icon: 'EditIcon'
      }
    ]
  }

  get isExternalCharacter() {
    return this.$route.path.includes('view')
  }

  async mounted() {
    const characterSet = this.isExternalCharacter ? this.userCharacters : this.characters
    const alreadyMountedCharacter = characterSet.find(e => e.id == this.$route.params.characterId)

    if (this.viewCharacter?.id != this.$route.params.characterId && !alreadyMountedCharacter) {
      this.$router.replace('/gm')
      return
    }

    this.currentCharacter = alreadyMountedCharacter ?? this.viewCharacter
  }
}
